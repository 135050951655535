<template>
  <Modal
    :title="'Request Overtime'"
    :show="show"
    :class="'modal-allowed-capacity'"
    :isCancel="false"
    :isSubmit="false"
    @hide="hide"
    @onSubmit="onSubmit"
  >
    <template #form>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(submit)"
          class="is-label-14px-normal form-timeoff-type"
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <ValidationProvider
                name="date"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Date"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-datepicker
                    placeholder="Enter Date"
                    icon-right="calendar"
                    id="date-picker-calendar"
                    trap-focus
                    :locale="locale"
                    v-model="formData.date"
                    :min-date="minDate"
                    :disabled="isEditing && notUpdatable"
                  ></b-datepicker>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <ValidationProvider
                name="duration"
                v-slot="{ errors }"
                rules="required|numeric"
              >
                <b-field
                  label="Duration Time"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    placeholder="Enter Duration Time"
                    v-model="formData.hours"
                    class="attendance-datepicker"
                    :disabled="isEditing && notUpdatable"
                  />
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-12">
              <ValidationProvider
                name="start"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Select time"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-timepicker
                    placeholder="Click to select time"
                    icon="clock"
                    v-model="formData.formattedStartTime"
                    :disabled="isEditing && notUpdatable"
                    :hour-format="hourFormat"
                    :locale="locale"
                  ></b-timepicker>
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-12 request-section-radio-tooltip">
              <div v-if="!isEditing">
                <ValidationProvider name="additional approver">
                  <PICTooltip />
                  <PIC
                    :isEditing="isEditing"
                    :listUser="listUser"
                    :isFetchingUser="isFetchingUser"
                    @getUser="getUserList"
                    @selectUser="selectUser"
                    @getMoreUser="getMoreUserList"
                  />
                </ValidationProvider>
              </div>
              <div v-else>
                <b-field label="PIC">
                  <p>
                    <b-icon icon="account" class="request-approver-icon mr-2" />
                    {{ selectedUser }}
                  </p>
                </b-field>
              </div>
            </div>
            <div class="column is-12">
              <b-field label="Purpose">
                <b-input
                  type="textarea"
                  v-model="formData.reason"
                  placeholder="Please write your purpose"
                  :disabled="isEditing && notUpdatable"
                ></b-input>
              </b-field>
            </div>
            <div class="columns container px-2">
              <div class="column">
                <div class="buttons">
                  <b-button
                    @click="hide()"
                    class="button is-light is-fullwidth"
                  >
                    Cancel
                  </b-button>
                </div>
              </div>

              <div class="column">
                <div class="buttons">
                  <b-button
                    type="is-primary"
                    expanded
                    :loading="loadingSubmit"
                    :disabled="loadingSubmit || notUpdatable"
                    @click="onSubmit"
                  >
                    {{ isEditing ? 'Update' : 'Save' }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </Modal>
</template>

<script>
// components
import Modal from '../../../components/Modals/Modal.vue'
import PICTooltip from '../../../components/PIC/PICTooltip.vue'
import PIC from '../../../components/PIC/PIC.vue'

export default {
  components: { Modal, PICTooltip, PIC },

  data() {
    return {}
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    notUpdatable: {
      type: Boolean,
      default: false,
    },
    listUser: {
      type: Array,
      default: null,
    },
    isFetchingUser: {
      type: Boolean,
      default: false,
    },
    selectedUser: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: '',
    },
    minDate: {
      type: Date,
      default: null,
    },
    hourFormat: {
      type: Date,
      default: null,
    },
  },

  methods: {
    /**
     * Emit hide modal component.
     */
    hide() {
      this.$emit('hide')
    },

    /**
     * Emit Submit modal.
     */
    onSubmit() {
      this.$emit('submit')
    },

    /**
     * Emit fetch user list.
     */
    getUserList(event) {
      this.$emit('getUserList', event)
    },

    /**
     * Emit select user.
     * @param event listener
     */
    selectUser(event) {
      this.$emit('selectUser', event)
    },

    /**
     * Emit get more user list.
     */
    getMoreUserList() {
      this.$emit('getMoreUserList')
    },
  },
}
</script>
