<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Overtime">
      <template #page-header>
        <h2 class="page-title">Overtime</h2>
        <r-menu-tab :level="3" />
      </template>
      <template #action-bar>
        <RequestHeader
          :search="true"
          @addNew="openModalRequest"
          :lodaData="isOvertimePersonalLoading"
          @onSearch="searchIconClick($event)"
        ></RequestHeader>
      </template>

      <template #page-content>
        <div
          id="table-request-employment"
          class="table-timeoff-type table-request-personal"
        >
          <b-table
            :data="overtimePersonal"
            :per-page="perPage"
            :loading="isOvertimePersonalLoading"
            @page-change="onPageChange"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkbox-position="left"
            class="employment-directory-table table-log-activity-personal general-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column field="date" label="Date" v-slot="props" sortable>
                <span class="is-capitalize">
                  {{ formatDate(props.row.date) }}
                </span>
              </b-table-column>

              <b-table-column
                field="hours"
                label="Estimation Duration"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ props.row.hours }}
                </span>
              </b-table-column>

              <b-table-column
                field="request_reason"
                label="Reason"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ props.row.reason }}
                </span>
              </b-table-column>

              <b-table-column
                field="status"
                label="Status"
                v-slot="props"
                sortable
              >
                <span
                  :class="`is-capitalize
                    ${determineFCByStatus(props.row.status)}`"
                >
                  {{ props.row.status }}
                </span>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="created_at"
                label="Created At"
                sortable
                width="14%"
              >
                <span>
                  {{ formatDate(props.row.createdAt) }}
                </span>
              </b-table-column>
              <b-table-column
                field="action"
                label="Action"
                v-slot="props"
                centered
              >
                <b-icon
                  v-if="
                    props.row.status === 'approved' && !props.row.files.length
                  "
                  icon="file-upload-outline"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="openModalReport(props.row)"
                />

                <b-icon
                  v-if="
                    props.row.status === 'approved' && props.row.files.length
                  "
                  icon="file-eye-outline"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="getOpenFile(props.row)"
                />

                <b-icon
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="
                    openModalRequest(overtimePersonal[props.index])
                  "
                />
                <b-icon
                  icon="eye"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="openSidebar(props.row)"
                />
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>

        <b-sidebar
          v-if="isRightBarOpen && form != null"
          can-cancel
          type="is-light"
          fullheight
          :fullwidth="fullWidth"
          :overlay="true"
          right
          v-model="isRightBarOpen"
        >
          <div class="rightbar-detail-top-content-container">
            <div class="right-bar-detail-top-content">
              <p class="has-text-weight-black is-size-4 title">
                Overtime Details
              </p>
              <b-icon
                icon="close"
                class="x-close-btn"
                custom-size="mdi-18px"
                @click.native="closeSidebar()"
              />
              <img
                class="profile-picture"
                :src="determineProfilePicture(currentUser)"
              />

              <p class="has-text-weight-black is-size-4 full-name">
                {{ currentUser.firstName }} {{ currentUser.middleName }}
                {{ currentUser.lastName }}
              </p>
            </div>

            <div class="columns is-multiline p-4 is-size-6">
              <div class="column is-4">Estimation Duration</div>
              <div class="column is-8 has-text-weight-bold">
                {{
                  selectedColumn.hours === 1
                    ? selectedColumn.hours + ' hour'
                    : selectedColumn.hours + ' hours'
                }}
              </div>
              <div class="column is-4">PIC</div>
              <div class="column is-8 has-text-weight-bold">
                {{
                  selectedColumn.history
                    ? selectedColumn.history[0].fullName
                    : '-'
                }}
              </div>
              <div class="column is-4">Status</div>
              <div class="column is-8 has-text-weight-bold is-capitalized">
                {{ selectedColumn.status }}
              </div>
              <div class="column is-4">Reason</div>
              <div class="column is-8 has-text-weight-bold is-capitalized">
                {{ selectedColumn.reason }}
              </div>
              <div class="column is-12">
                <TimeOffApprovalHistoryComponent
                  :approval-history="selectedColumn.history"
                />
              </div>
              <div
                class="column is-12"
                v-if="
                  selectedColumn.status === 'waiting' ||
                  selectedColumn.status === 'approved'
                "
              >
                <b-button expanded @click="cancelOvertime(selectedColumn.id)">
                  Cancel Request
                </b-button>
              </div>
            </div>
          </div>
        </b-sidebar>

        <!-- Modal create/edit -->
        <OvertimePersonalModal
          :show="isModalOpen"
          :formData="form"
          :loadingSubmit="isSubmitting"
          :isEditing="isEditing"
          :listUser="listUser"
          :isFetchingUser="isFetchingUser"
          :notUpdatable="notUpdatable"
          :selectedUser="selectedUser"
          :locale="locale"
          :minDate="minDate"
          :hourFormat="hourFormat"
          @hide="closeModalRequest"
          @submit="submit"
          @getUserList="getUserList($event)"
          @selectUser="selectUser($event)"
          @getMoreUserList="getMoreUserList"
        />

        <!-- Modal report -->
        <OvertimeReportModal
          :isEditing="isEditing"
          :notUpdatable="notUpdatable"
          :loadingSubmit="isSubmitting"
          :show="modalReport"
          :pdfFile="pdfFile"
          @hide="closeModalReport"
          @submit="onUploadFile"
          @onUpdate="onUploadPdf"
        />
      </template>
    </r-page>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { determineFontColorByStatus, showToast } from '@/services/util'
import moment from 'moment-timezone'
import TimeOffApprovalHistoryComponent from '../TimeOff/TimeOffApprovalHistoryComponent.vue'
import isNotUpdatable from '../Script/isNotUpdatable'
import ApiService from '../../../services/common/api.service'
import debounce from 'lodash/debounce'
import RequestHeader from '../../../components/Header/RequestHeader.vue'
import OvertimePersonalModal from './OvertimePersonalModal.vue'
import OvertimeReportModal from './OvertimeReportModal.vue'

export default {
  components: {
    TimeOffApprovalHistoryComponent,
    RequestHeader,
    OvertimePersonalModal,
    OvertimeReportModal,
  },
  data() {
    const today = new Date()
    const min = new Date()
    min.setHours(9)
    min.setMinutes(0)
    const max = new Date()
    max.setHours(18)
    max.setMinutes(0)
    return {
      modalBtn: [
        {
          title: 'Cancel',
          action: this.closeModalRequest,
        },

        {
          title: 'Save',
          action: this.submit,
        },
      ],

      hourFormat: undefined, // Browser locale
      isLocale: undefined, // Browser locale

      // offsetDiff in hour
      offSetDiff: this.getOffsetDiff(),

      form: {
        id: null,
        additionalApproverId: null,
        date: null,
        hours: null,
        reason: null,
        status: null,
        formattedStartTime: null,
      },
      stickyHeaders: true,
      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,
      total: 0,

      defaultSortOrder: 'desc',
      sortField: 'created_at',
      sortOrder: 'desc',

      isOvertimePersonalLoading: false,
      isLoadMore: false,
      isModalOpen: false,
      isEditing: false,
      notUpdatable: null,
      selectedColumn: null,
      isRightBarOpen: false,
      fullWidth: false,
      right: false,

      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      isSubmitting: null,
      locale: 'en-GB',

      // User Autocomplete
      listUser: [],
      isFetchingUser: false,
      pageUserList: 1,
      lastPageUserList: 1,
      selectedUser: '',
      searchUser: '',
      modalReport: false,
      pdfFile: null,
      uploadId: null,
    }
  },
  computed: {
    ...mapGetters({
      overtimePersonal: 'overtimeModule/getOvertimeRequestData',
      currentUser: 'auth/currentUser',
      overtimeDetail: 'overtimeModule/getOvertimeDetail',
    }),

    setModalBtn() {
      if (this.isEditing) {
        return [
          {
            title: 'Cancel',
            bindClass: 'button is-light is-fullwidth',
            action: this.closeModalRequest,
          },

          {
            title: 'Update',
            bindClass: 'button is-primary is-fullwidth',
            loading: this.isSubmitting,
            action: this.submit,
          },
        ]
      } else {
        return [
          {
            title: 'Cancel',
            bindClass: 'button is-light is-fullwidth',
            action: this.closeModalRequest,
          },

          {
            title: 'Save',
            bindClass: 'button is-primary is-fullwidth',
            loading: this.isSubmitting,
            action: this.submit,
          },
        ]
      }
    },
  },
  methods: {
    ...mapActions({
      actionLoadOvertimePersonal: 'overtimeModule/fetchOvertimeRequestData',
      saveOvertime: 'overtimeModule/saveOvertime',
      uploadReport: 'overtimeModule/uploadReport',
      updateOvertimeRequest: 'overtimeModule/updateOvertimeRequest',
      actionLoadOvertimeDetail: 'overtimeModule/fetchOvertimeDetail',
    }),

    ...mapMutations({
      setOvertimePersonal: 'overtimeModule/setOvertimeRequestData',
      setOvertimeDetail: 'overtimeModule/setOvertimeModule',
    }),

    /**
     * Change page behavior
     * @param {integer} page - number of page
     */
    onPageChange(page) {
      this.page = page
      this.loadOvertimePersonalList()
    },

    /**
     * Handle upload pdf
     * @param {integer} files - array of files
     */
    onUploadPdf(files) {
      this.pdfFile = [files]
    },

    /**
     * Sort table
     * @param {String} field - name of field
     * @param {String} order - asc or desc
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     * Set Time format
     * @param {Date} str - date time string
     */
    formatTimeSelect(str) {
      return moment(moment.utc(str).local().toISOString()).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    },

    /**
     * Offset time when user sets timezone to other region
     */
    getOffsetDiff() {
      // if you live in asia/jakarta, offset will be 420 (7 * 60 minute)
      let offsetLocal = moment.tz(new Date(), moment.tz.guess()).utcOffset()
      // if you use timezone asia/tokyo, offset will be 540 (9 * 60)
      let offSetTimeZone = moment(new Date()).utcOffset()

      let diff = offsetLocal - offSetTimeZone
      if (diff < 0) {
        diff *= -1
      }
      // convert from minutes to hours
      diff /= 60
      return diff
    },

    /**
     * Open File on mouse click
     * @param {Object} row - File object
     */
    getOpenFile(row) {
      const filePath = row.files.map((val) => {
        return val.url
      })

      let fullUrl = process.env.VUE_APP_API_URL + filePath.toString()
      window.open(fullUrl, '_blank')
    },

    /**
     * Open report modal
     * @param {Object} row - Report Object from table
     */
    openModalReport(row) {
      this.uploadId = row.id
      this.modalReport = true
    },

    /**
     * Close Report Modal
     */
    closeModalReport() {
      this.modalReport = false
      this.resetForm()
    },

    /**
     * Upload Report File
     */
    async onUploadFile() {
      const form = {
        id: this.uploadId,
        files: this.pdfFile[0],
      }
      this.isSubmitting = true
      try {
        await this.uploadReport(form)
        this.modalReport = false
        this.resetForm()
        await this.resetState()
        showToast('Successfully added data!', 'is-success', 'is-top')
      } catch (e) {
        showToast('Failed to upload report', 'is-danger', 'is-top')
      }
      this.isSubmitting = false
    },

    /**
     * Set table status color
     * @param {String} status - request status
     * @return determineFontColorByStatus method
     */
    determineFCByStatus(status) {
      return determineFontColorByStatus(status)
    },

    /**
     * Set Format Date
     * @param {Date} str - date string
     * @return {Date} formatted date
     */
    formatDate(str) {
      if (str) {
        return moment(str).format('DD MMMM YYYY')
      }
    },

    /**
     * Search table data
     * @param {String} evt - keyword search
     */
    async searchIconClick(evt) {
      await this.resetState(evt)
    },

    /**
     * Load more data of overtime personal list
     */
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadOvertimePersonalList()
        this.isLoadMore = false
      }
    },

    /**
     * Reset state of table when form is created, updated
     * @param {String} searchInput - keyword search (optional)
     */
    async resetState(searchInput) {
      this.page = 0
      this.lastPage = 0
      this.setOvertimePersonal([])
      await this.loadOvertimePersonalList(searchInput)
    },

    /**
     * Submit overtime request
     */
    async submit() {
      try {
        let momentStartTime = moment(this.form.formattedStartTime)
        let startTimeUTC = momentStartTime
          .utc()
          .subtract(this.offSetDiff, 'hours')

        let momentEndTime = moment(this.form.formattedEndTime)
        let endTimeUTC = momentEndTime.utc().subtract(this.offSetDiff, 'hours')
        if (!this.isEditing) {
          this.isSubmitting = true
          let formRequest = new FormData()

          formRequest.append('start_time', startTimeUTC.format('HH:mm:ss'))
          formRequest.append(
            'date',
            moment(this.form.date).format('YYYY-MM-DD')
          )
          formRequest.append('hours', this.form.hours)
          formRequest.append('reason', this.form.reason)
          formRequest.append(
            'additional_approver_id',
            this.form.additionalApproverId
          )

          if (!this.form.additionalApproverId) {
            formRequest.delete('additional_approver_id')
          }

          let response = await ApiService.post(
            '/api/space-roketin/overtime/v3/request',
            formRequest,
            true
          )
          this.form = {
            attendance_id: '',
            start_time: null,
            end_time: null,
          }
          this.setOvertimePersonal([
            ...this.overtimePersonal,
            response.data.data,
          ])
          this.closeModalRequest()
          this.resetState()
          showToast('Add Success', 'is-success', 'is-top')
          this.isSubmitting = false
        } else {
          this.isSubmitting = true
          let formRequest = new FormData()

          formRequest.append('start_time', startTimeUTC.format('HH:mm:ss'))
          formRequest.append('hours', this.form.hours)
          formRequest.append(
            'date',
            moment(this.form.date).format('YYYY-MM-DD')
          )
          formRequest.append('reason', this.form.reason)
          formRequest.append('_method', 'PUT')

          await ApiService.post(
            '/api/space-roketin/overtime/v3/request/' + this.form.id,
            formRequest,
            true
          )

          // //for table ---------------------------------------------------------
          let overtimePersonalVue = [...this.overtimePersonal]

          let index = overtimePersonalVue.findIndex(
            (t) => t.id === this.form.id
          )

          if (index >= 0) {
            overtimePersonalVue[index].newStartTime = startTimeUTC.toISOString()
            overtimePersonalVue[index].newEndTime = endTimeUTC.toISOString()
          }
          this.isSubmitting = false
          this.setOvertimePersonal([...overtimePersonalVue])
          this.closeModalRequest()
          this.resetState()
          showToast('Edit Success', 'is-success', 'is-top')
        }
      } catch (e) {
        console.log(e)
        if (e.response?.data?.message) {
          showToast(e.response.data.message, 'is-danger', 'is-top')
          this.isSubmitting = false
        }
      }
    },

    /**
     * Cancel Overtime Request
     * @param {integer} id - Request Id
     */
    async cancelOvertime(id) {
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: `Are you sure? You can't undo this action.`,
      })

      if (res && res.isConfirmed) {
        const form = new FormData()

        form.append('status', 'canceled')
        this.isSubmitting = true
        try {
          form.append('_method', 'PUT')
          const data = { id, form }
          await this.updateOvertimeRequest(data)
          showToast('Request canceled', 'is-success', 'is-top')
          this.isRightBarOpen = false
        } catch (e) {
          showToast(e.response.message, 'is-danger', 'is-top')
        }
      }
    },

    /**
     * Load Overtime Personal List data.
     * @param {String} searchInput - keyword search (optional)
     */
    async loadOvertimePersonalList(searchInput) {
      this.isOvertimePersonalLoading = true

      try {
        const response = await this.actionLoadOvertimePersonal({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: searchInput,
        })

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isOvertimePersonalLoading = false
    },

    /**
     * Open Request Modal. If row present,
     * set form to include data from selected table row
     * @param {integer} row - table row index (optional)
     */
    openModalRequest(row) {
      if (row) {
        const dateTime = `${row.date}T${row.startTime}`
        this.isEditing = true
        this.form = {
          id: row.id,
          date: new Date(row.date),
          hours: row.hours,
          reason: row.reason,
          status: row.status,
          formattedStartTime: new Date(this.formatTimeSelect(dateTime)),
        }
        this.selectedUser = row.history ? row.history[0].fullName : '-'
        this.notUpdatable = isNotUpdatable.check(row.status, row.history)
      }
      this.isModalOpen = true
    },

    /**
     * Close Request Modal
     */
    closeModalRequest() {
      this.resetForm()
      this.isModalOpen = false
      this.isEditing = false
    },

    /**
     * Reset form when close/ hide
     */
    resetForm() {
      this.form = {
        id: null,
        date: null,
        hours: null,
        formattedStartTime: null,
        reason: null,
        additionalApproverId: null,
      }
      this.searchUser = null
      this.selectedUser = null
      this.pdfFile = null
    },

    /**
     * Open Sidebar content from table
     * @param {Object} overtime - selected overtime data from table row
     * @param {integer} id - selected data id
     */
    async openSidebar(overtime, id) {
      if (id) {
        let response = await this.actionLoadOvertimeDetail(id)
        this.selectedColumn = response.data.data
      } else {
        this.selectedColumn = overtime
      }
      this.isRightBarOpen = true
    },

    /**
     * Close Sidebar
     */
    closeSidebar() {
      this.isRightBarOpen = false
    },

    /**
     * Determine Profile picture from database
     * @param {Array} user - user info that is currently logged in
     * @return {String} user profile picture url
     */
    determineProfilePicture(user) {
      if (!user.profilePictureUrl) {
        if (user.gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user.profilePictureUrl
    },

    /**
     * Select User from PIC database
     * @param {Object} option - selected user object (optional)
     */
    selectUser(option) {
      if (option) {
        this.selectedUser = option.fullName
        this.form.additionalApproverId = option.id
      }
    },

    /**
     * Get User list from database
     * @return {Object} listUser - User List
     */
    getUserList: debounce(function (name) {
      this.form.additionalApproverId = null
      if (this.selectedUser !== name) {
        this.selectedUser = name
        this.listUser = []
        this.pageUserList = 1
        this.lastPageUserList = 1
      }
      if (!name.length) {
        this.listUser = []
        this.pageUserList = 1
        this.lastPageUserList = 1
        return
      }
      if (this.pageUserList > this.lastPageUserList) {
        return
      }
      this.isFetchingUser = true
      ApiService.get('/api/space-roketin/user/v3', {
        search: this.selectedUser,
        page: this.pageUserList,
      })
        .then((response) => {
          response.data.data.forEach((item) => this.listUser.push(item))
          this.pageUserList++
          this.lastPageUserList = response.data.meta.lastPage
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          this.isFetchingUser = false
        })
    }, 500),

    /**
     * Get more user list when auto complete scrolled
     * @return {Object} listUser - User List
     */
    getMoreUserList: debounce(function () {
      this.getUserList(this.selectedUser)
    }, 250),
  },

  async mounted() {
    this.setOvertimePersonal([])
    await this.loadOvertimePersonalList()
    if (this.$route.query.id) {
      await this.openSidebar(null, this.$route.query.id)
    }
  },
}
</script>
