var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{class:'modal-allowed-capacity',attrs:{"title":'Request Overtime',"show":_vm.show,"isCancel":false,"isSubmit":false},on:{"hide":_vm.hide,"onSubmit":_vm.onSubmit},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"is-label-14px-normal form-timeoff-type",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Date","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":"Enter Date","icon-right":"calendar","id":"date-picker-calendar","trap-focus":"","locale":_vm.locale,"min-date":_vm.minDate,"disabled":_vm.isEditing && _vm.notUpdatable},model:{value:(_vm.formData.date),callback:function ($$v) {_vm.$set(_vm.formData, "date", $$v)},expression:"formData.date"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"duration","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Duration Time","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{staticClass:"attendance-datepicker",attrs:{"placeholder":"Enter Duration Time","disabled":_vm.isEditing && _vm.notUpdatable},model:{value:(_vm.formData.hours),callback:function ($$v) {_vm.$set(_vm.formData, "hours", $$v)},expression:"formData.hours"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"start","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Select time","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-timepicker',{attrs:{"placeholder":"Click to select time","icon":"clock","disabled":_vm.isEditing && _vm.notUpdatable,"hour-format":_vm.hourFormat,"locale":_vm.locale},model:{value:(_vm.formData.formattedStartTime),callback:function ($$v) {_vm.$set(_vm.formData, "formattedStartTime", $$v)},expression:"formData.formattedStartTime"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12 request-section-radio-tooltip"},[(!_vm.isEditing)?_c('div',[_c('ValidationProvider',{attrs:{"name":"additional approver"}},[_c('PICTooltip'),_c('PIC',{attrs:{"isEditing":_vm.isEditing,"listUser":_vm.listUser,"isFetchingUser":_vm.isFetchingUser},on:{"getUser":_vm.getUserList,"selectUser":_vm.selectUser,"getMoreUser":_vm.getMoreUserList}})],1)],1):_c('div',[_c('b-field',{attrs:{"label":"PIC"}},[_c('p',[_c('b-icon',{staticClass:"request-approver-icon mr-2",attrs:{"icon":"account"}}),_vm._v(" "+_vm._s(_vm.selectedUser)+" ")],1)])],1)]),_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"label":"Purpose"}},[_c('b-input',{attrs:{"type":"textarea","placeholder":"Please write your purpose","disabled":_vm.isEditing && _vm.notUpdatable},model:{value:(_vm.formData.reason),callback:function ($$v) {_vm.$set(_vm.formData, "reason", $$v)},expression:"formData.reason"}})],1)],1),_c('div',{staticClass:"columns container px-2"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"button is-light is-fullwidth",on:{"click":function($event){return _vm.hide()}}},[_vm._v(" Cancel ")])],1)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":"is-primary","expanded":"","loading":_vm.loadingSubmit,"disabled":_vm.loadingSubmit || _vm.notUpdatable},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.isEditing ? 'Update' : 'Save')+" ")])],1)])])])])]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }