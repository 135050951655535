<template>
  <Modal
    :title="'Overtime Report'"
    :show="show"
    :class="'modal-allowed-capacity'"
    :isCancel="false"
    :isSubmit="false"
    @hide="hide"
    @onSubmit="onSubmit"
  >
    <template #form>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(onUploadFile)"
          class="is-label-14px-normal form-timeoff-type"
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <ValidationProvider
                name="date"
                v-slot="{ errors }"
                rules="ext:pdf"
                ref="validator"
              >
                <b-field
                  label="Upload File"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-upload
                    accept="application/pdf"
                    v-model="pdfFileData"
                    :multiple="false"
                    drag-drop
                    expanded
                  >
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon
                            icon="file-pdf-outline"
                            size="is-large"
                          ></b-icon>
                        </p>
                        <div v-for="(file, index) in pdfFile" :key="index">
                          <p v-if="pdfFile">{{ file.name }}</p>
                        </div>
                        <p v-if="!pdfFile">
                          Drop your files here or click to upload
                        </p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="columns container px-2">
              <div class="column">
                <div class="buttons">
                  <b-button
                    @click="hide()"
                    class="button is-light is-fullwidth"
                  >
                    Cancel
                  </b-button>
                </div>
              </div>

              <div class="column">
                <div class="buttons">
                  <b-button
                    :loading="loadingSubmit"
                    :disabled="isEditing && notUpdatable"
                    class="button is-primary is-fullwidth"
                    @click="onSubmit"
                  >
                    {{ isEditing ? 'Update' : 'Save' }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </Modal>
</template>

<script>
// components
import Modal from '../../../components/Modals/Modal.vue'

export default {
  components: { Modal },

  data() {
    return {}
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    pdfFile: {
      type: Array,
      default: null,
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    notUpdatable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    pdfFileData: {
      get() {
        return this.pdfFile
      },
      set(newValue) {
        this.$emit('onUpdate', newValue)
      },
    },
  },

  methods: {
    /**
     * Emit hide modal component.
     */
    hide() {
      this.$emit('hide')
    },

    /**
     * Emit Submit modal.
     */
    onSubmit() {
      this.$emit('submit')
    },
  },
}
</script>
